var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"audio-player"},[_c('div',{staticClass:"audio__btn-wrap"},[(_vm.showPlaybackRate)?_c('div',{staticClass:"audio__play-rate",style:({
          color: _vm.themeColor,
        })},[_c('span',{on:{"click":function($event){$event.stopPropagation();_vm.isShowRates = !_vm.isShowRates}}},[_vm._v(_vm._s(_vm._f("rateFilter")(_vm.playbackRate)))]),_c('transition',{attrs:{"name":"fade-rate"}},[_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowRates),expression:"isShowRates"}],staticClass:"audio__play-rate__dropdown",style:({
              border: ("1px solid " + _vm.themeColor),
            })},_vm._l((_vm.playbackRates),function(rate){return _c('li',{key:'pr_' + rate,on:{"click":function($event){$event.stopPropagation();return _vm.handleSetPlaybackRate(rate)}}},[_vm._v(" "+_vm._s(_vm._f("rateFilter")(rate))+" ")])}),0)])],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPrevButton),expression:"showPrevButton"}],staticClass:"audio__play-prev",class:{ disable: !_vm.isLoop && _vm.currentPlayIndex === 0 },on:{"click":function($event){$event.stopPropagation();return _vm.playPrev.apply(null, arguments)}}},[_c('svg',{staticClass:"audio__play-icon",style:({
            color: _vm.themeColor,
          }),attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#icon-play-prev"}})])]),(!_vm.isPlaying && _vm.showPlayButton)?_c('div',{staticClass:"audio__play-start",on:{"click":function($event){$event.stopPropagation();return _vm.play.apply(null, arguments)}}},[_c('svg',{staticClass:"audio__play-icon",style:({
            color: _vm.themeColor,
          }),attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#icon-play"}})])]):(_vm.showPlayButton)?_c('div',{staticClass:"audio__play-pause",on:{"click":function($event){$event.stopPropagation();return _vm.pause.apply(null, arguments)}}},[_c('svg',{staticClass:"audio__play-icon",style:({
            color: _vm.themeColor,
          }),attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#icon-play-pause"}})])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showNextButton),expression:"showNextButton"}],staticClass:"audio__play-next",class:{
          disable: !_vm.isLoop && _vm.currentPlayIndex === _vm.audioList.length - 1,
        },on:{"click":function($event){$event.stopPropagation();return _vm.playNext.apply(null, arguments)}}},[_c('svg',{staticClass:"audio__play-icon",style:({
            color: _vm.themeColor,
          }),attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#icon-play-next"}})])]),(_vm.showVolumeButton)?_c('div',{staticClass:"audio__play-volume-icon-wrap"},[_c('svg',{staticClass:"audio__play-icon",style:({
            color: _vm.themeColor,
          }),attrs:{"aria-hidden":"true"},on:{"click":function($event){$event.stopPropagation();return _vm.handleVolumeIconTouchstart.apply(null, arguments)}}},[_c('use',{attrs:{"xlink:href":_vm.currentVolume ? "#icon-play-volume" : "#icon-play-volume-no"}})]),_c('transition',{attrs:{"name":"fade-volume"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowVolume),expression:"isShowVolume"}],ref:"playVolumeWrap",staticClass:"audio__play-volume-wrap",on:{"click":function($event){$event.stopPropagation();return _vm.handleVolumePanmove.apply(null, arguments)},"panmove":_vm.handleVolumePanmove,"panend":_vm.handleVolumePanend}},[_c('div',{ref:"playVolume",staticClass:"audio__play-volume",style:({
                height: _vm.currentVolume * 100 + '%',
                backgroundColor: _vm.themeColor,
              })})])])],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowNotice),expression:"isShowNotice"}],staticClass:"audio__notice"},[_vm._v(" "+_vm._s(_vm.noticeMessage)+" ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showProgressBar),expression:"showProgressBar"}],ref:"audioProgressWrap",staticClass:"audio__progress-wrap",on:{"click":function($event){$event.stopPropagation();return _vm.handleClickProgressWrap.apply(null, arguments)}}},[_c('div',{ref:"audioProgress",staticClass:"audio__progress",style:({
          backgroundColor: _vm.themeColor,
        })}),_c('div',{ref:"audioProgressPoint",staticClass:"audio__progress-point",style:({
          backgroundColor: _vm.themeColor
        })})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showProgressBar),expression:"showProgressBar"}],staticClass:"audio__time-wrap"},[_c('div',{staticClass:"audio__current-time"},[_vm._v(" "+_vm._s(_vm.currentTimeFormatted)+" ")]),_c('div',{staticClass:"audio__duration"},[_vm._v(" "+_vm._s(_vm.durationFormatted)+" ")])]),_c('audio',_vm._b({ref:"audio",staticClass:"audio-player__audio",attrs:{"src":_vm.audioList[_vm.currentPlayIndex]},on:{"ended":_vm.onEnded,"timeupdate":_vm.onTimeUpdate,"loadedmetadata":_vm.onLoadedmetadata}},'audio',_vm.$attrs,false),[_vm._v(" 浏览器太老咯，请升级浏览器吧~ ")])])}
var staticRenderFns = []

export { render, staticRenderFns }