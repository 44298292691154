
function markerGeoFormat (ls, flag) {
    if(!ls.length) return []
    var arr = []
    var list = JSON.parse(JSON.stringify(ls))
    list.forEach((item,index) => {
        item.mapCoordinate = item.mapCoordinate.split(',')
        let obj = {
            id: item.mapId.toString(),
            styleId: flag?`point${index+1}`:item.mapIconStoId,
            position: new window.TMap.LatLng(item.mapCoordinate[0], item.mapCoordinate[1]),
            properties: { ...item, name: item.mapSpotName }
        }
        arr.push(obj)
    })
    if (arr.length > 1) {
        arr.sort((a,b) => a.properties.distance - b.properties.distance)
    }
    return arr
}

function secIntrFormat (str) {
    if(!str) return ''
    let arr = str.split('\n')
    let newStr = ''
    arr.forEach(item => {
        newStr += `<div class="secIntrItem">${item}</div>`
    })
    return newStr
}
function cooFormat (e) { //坐标格式化 'lat,lng'或['lat,lng']
    let arr=[]
    if (typeof e == 'string') {
        arr = e.split(',')
        return new window.TMap.LatLng(Number(arr[0]), Number(arr[1]))
    } else {
        e.forEach(item => {
            arr.push(new window.TMap.LatLng(item.split(',')[0], item.split(',')[1]))
        })
        return arr
    }
}

/**
 * 清除marker选中放大状态
 */
function clearMarkerStyleChecked (markers, tourMapList) {
    for (let i in markers['styles']) {
        if (/_a$/.test(markers['styles'][i])) {
            delete markers['styles'][i]
        }
    }
    tourMapList.forEach(item => {
        if (/_a$/.test(item.mapIconStoId)) {
            item.mapIconStoId = item.mapIconStoId.replace(/_a$/g, '')
        }
    })

}

/**
 * 清除路线图标放大
 */
function clearRouteMarkerStyle(routeStyles) {
    for (let i in routeStyles) {
        routeStyles[i]['width'] = 30
        routeStyles[i]['height'] = 30
        routeStyles[i]['anchor'] = {x: 14, y: 28}
    }
}
export {
    markerGeoFormat,
    secIntrFormat,
    cooFormat,
    clearMarkerStyleChecked,
    clearRouteMarkerStyle
}
