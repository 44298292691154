let _this
function Touch (params, t) {
    _this = t
    init(params);
    bindEvent();
}

function init (params) {
    _this.contEl = params.shadowEl;
    _this.vanTabs = params.vanTabs;
    _this.Setting = params.Setting;
    _this.direction = 'toBottom'
    _this.h = document.body.clientHeight;
    _this.top = params.top ? _this.h * params.top : _this.h * 0.1;
    _this.bottom = params.bottom ? _this.h * params.bottom : _this.h * 0.9;
    if (_this.top >= _this.bottom) {
        return;
    }
    _this.duration = params.duration ? params.duration : 0.3;
    _this.y = _this.bottom;
    _this.contEl.style.top = _this.y + "px";
    _this.contEl.style.height = _this.h - _this.y + "px";
}
function touchstart (e) {
    setDom("start");
    _this.startY = e.touches[0].clientY;
    _this.startX = e.touches[0].clientX;
    _this.lastClientY = _this.startY;
    _this.lastClientX = _this.startX;
    _this.state = _this.contEl.scrollTop == 0 ? "unscroll" : "scroll";
}
function touchmove (e) {
    if (_this.contEl.scrollTop != 0) {
        _this.state = "scroll";
    }
    if (_this.contEl.clientHeight < _this.contEl.scrollHeight && _this.contEl.scrollTop != 0) {
        return
    }
    var clientY = e.touches[0].clientY;
    var clientX = e.touches[0].clientX;
    var offsetY = clientY - _this.startY
    var offsetX = clientX - _this.startX
    if (Math.abs(offsetY) > Math.abs(offsetX)) {
        _this.direction = offsetY > 0 ? "toBottom" : "toTop"
    } else {
        _this.direction = offsetX > 0 ? "toRight" : "toLeft"
    }
    /*两种情况执行方法：
    1. 当前元素不在顶部
    2. 运动方向向下&&contBox元素的滚动值为0&&当前状态不是滚动*/
    if (_this.direction == "toBottom" || _this.direction == "toTop") {
        if (_this.y != _this.top || (_this.direction == "toBottom" && _this.contEl.scrollTop == 0)) {
            e.preventDefault();
            _this.y += clientY - _this.lastClientY;
            _this.y = _this.y > _this.bottom ? _this.bottom : _this.y;
            _this.y = _this.y < _this.top ? _this.top : _this.y;
            _this.height = _this.h - _this.y;
            setDom("move");
        }
        _this.lastClientY = clientY;
    }
}
function touchend (e) {
    if (_this.state == "scroll" || Math.abs(e.changedTouches[0].clientY - _this.startY) < 10) {
        return;
    }
    _this.y = _this.direction == "toBottom" ? _this.bottom : _this.top;
    _this.height = _this.h - _this.y;
    setDom("end");
}
function setDom (state) {
    if (_this.contType.includes('detail')&&!_this.secPointDetail.mapDesc||(!_this.currMarkerGeo.length&&(_this.spotType!=1&&_this.spotType!=2))) return
    switch (state) {
        case "start":
            _this.contEl.style.transition = "none";
            break;
        case "move":
            _this.contEl.style.height = _this.height + "px";
            _this.contEl.style.top = _this.y + "px";
            break;
        case "end":
            if (_this.direction == "toBottom" || _this.direction == "toTop") {
                _this.contEl.style.height = _this.height + "px";
                _this.contEl.style.top = _this.y + "px";
                _this.contEl.style.transition = `all ${_this.duration}s linear`
                if (_this.direction == "toBottom") {
                    _this.vanTabs.style.height = "40px";
                    _this.Setting.style.top = "50px";
                    _this.vanTabs.style.transition = `all ${_this.duration}s linear`
                    _this.contPosition = 'bot'
                } else {
                    _this.vanTabs.style.height = "0";
                    _this.Setting.style.top = "10px";
                    _this.vanTabs.style.transition = `all ${_this.duration}s linear`
                    _this.contPosition = 'top'
                }
            }
            break;
    }
}
function bindEvent () {
    _this.contEl.addEventListener("touchstart", function (e) {
        touchstart(e);
    })
    _this.contEl.addEventListener("touchmove", function (e) {
        touchmove(e);
    })
    _this.contEl.addEventListener("touchend", function (e) {
        touchend(e);
    })
}

export {
    Touch,
    setDom
}