<template>
  <van-icon
    v-if="contType.includes('detail')"
    class="closeIcon"
    name="cross"
    size="18"
    @click="exit"
  />
</template>

<script>
import { Icon } from "vant";
export default {
  name: "exitIcon",
  props: {
    contType: {
      default: "type1",
      type: String
    }
  },
  components: {
    [Icon.name]: Icon
  },
  methods: {
    exit() {
      this.$emit("exitDetail");
    }
  }
};
</script>
<style lang="scss" scoped>
.closeIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}
</style>