// const URL = "https://ticket.syjava.com" // 宋云测试
// const URL = "https://hls-ticket.newpictures.cn" // 胡里山炮台
// const URL = "https://bshg-ticket.dlharlem.com" // 哈林篮球馆冰山慧谷店
// const URL = "https://ticket.whzsh.com" // 鄂州西山（旧，废弃）
const URL = "https://ticket.ezhouxishan.com" // 鄂州西山
// const URL = "https://ticket.1230u.com" // 宋云测试
// const URL = "https://ticket.htcyltd.cn"

export default {
    apiGetSecInfo: URL + '/wx/api/ticket/ScenicMessage/page', //景区信息
    apiGetSecList: URL + '/wx/api/wx/sys/pointType/list', //景点列表
    apiGetSecItem: URL + '/wx/api/wx/sys/tourPoint/query',
    apiGetAppKey: URL + '/wx/api/wx/applets/getJsApi',
    apiCalcDistance: URL + '/wx/api/wx/sys/txMap/distanceMatrix', //获取定位点到景点详情的距离（一对多）
    apiGetMapConfig: URL + '/wx/api/wx/applets/getMapConfig', // 获取地图key，中心点位置，遮罩层数据
}
