<template>
  <div class="topIcon">
    <img
      class="opImg"
      v-if="contPosition == 'top'"
      src="../assets/images/op_glide.svg"
      alt=""
    />
    <img class="opImg" v-else src="../assets/images/op_pull.svg" alt="" />
  </div>
</template>

<script>
export default {
  name: "controlIcon",
  props: {
    contPosition: {
      default: 'bot',
      type: String
    }
  }
};
</script>
<style lang="scss" scoped>
.topIcon {
  width: 100%;
  position: absolute;
  top: 7px;
  display: flex;
  justify-content: center;
  z-index: 1;
  .opImg {
    width: 30px;
  }
}
</style>