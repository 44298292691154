<template>
  <div id="app" ref="app">
    <div ref="vanTabs" class="vanTabsDiv">
      <van-tabs v-if="navList.length" class="vanTabs" v-model="active" color="#07c160" title-active-color="#07c160" @click="tabClick" swipe-threshold="3">
        <van-tab :title-class="{ tabActive: active == index }" v-for="(item, index) in navList" :title="item.spotTypeName" :key="index">
        </van-tab>
      </van-tabs>
    </div>
    <div id="map"></div>
    <div v-if="spotType == 1" class="contBox" :style="{ overflow: contPosition == 'top' ? 'auto' : 'hidden' }" ref="contBox">
      <controlIcon :contPosition="contPosition"/>
      <exitIcon :contType="contType" @exitDetail="exitDetail"/>
      <div :class="['contBoxTop', contType == 'type1_detail' && !secPointDetail.mapDesc ? 'borRadius10' : '',]">
        <div v-if="contType == 'type1'" class="scenicInfo">
          <div class="secListInfo">
            <div class="scenicTitle">
              <span class="scenicName">{{ secInfo.scenicName }}</span>
              <span v-if="secInfo.scenicLevel" class="scenicTag">{{secInfo.scenicLevel}}</span>
            </div>
            <div class="scenicAbs">
              <div class="scenicAbsLeft">
                <div class="absLeftItem">
                  <img src="./assets/images/sec_openTime.svg" alt=""/>
                  <span>{{ secInfo.scenicOpenTime }}</span>
                </div>
                <div class="absLeftItem">
                  <img src="./assets/images/sec_addr.svg" alt=""/>
                  <span>{{ secInfo.scenicAddress }}</span>
                </div>
              </div>
              <div class="scenicAbsRight">
                <div class="absRightCont" @click="handlePhone">
                  <img src="./assets/images/sec_tell.svg" alt=""/>
                  <span>电话</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="contType == 'type1_detail'" class="secDetail">
          <div class="secDetailTop">
            <div class="deTopLeft">
              <img v-if="secPointDetail.mapDefaultPic" :src="secPointDetail.mapDefaultPic" alt=""/>
              <img v-else src="./assets/images/defaultGeoPic.png" alt=""/>
            </div>
            <div class="deTopRit">
              <div class="secDeTitle">
                <span class="s_name">{{ secPointDetail.mapSpotName }}</span>
                <span class="s_dis" v-show="secPointDetail.distance">{{
                  secPointDetail.distance | distanceFormat
                }}</span>
              </div>
              <div v-if="audioList" class="s_voice">
                <audio-player
                    ref="audioPlayer"
                    :audio-list="audioList.filter(item => item.voiTypeId === voiTypeRadio).map((item) => item.fileUrl)"
                    theme-color="#f8d466"
                    @play="radioPlay"
                    @pause="radioPause"
                    @ended="radioEnd"/>
              </div>
            </div>
          </div>
          <div class="secDetailBot">
            <div class="s_navigator" @click.stop="toThere(secPointDetail)">
              <img class="s_icon" src="./assets/images/navigator_d.svg" alt=""/>
              <span class="s_span">去这里</span>
            </div>
            <div v-if="audioList" class="playerBtn" @click.stop="palyRadio">
              <img v-if="!radioPlaying" class="player_icon" src="./assets/images/radio_play.svg" alt=""/>
              <img v-else class="player_icon" src="./assets/images/radio_pause.svg" alt=""/>
              <span class="player_span">{{ radioPlaying ? "暂停" : "播放" }}讲解</span>
            </div>
          </div>
        </div>
      </div>
      <div :class="['contBoxCont',(contType === 'type1_detail' && !secPointDetail.mapDesc) || !secPointList.length ? 'disNone' : '' ]">
        <div class="scenicList" v-if="contType == 'type1' && secPointList.length" ref="boxList">
          <div class="scenicItem" v-for="(item, index) in secPointList" :key="index" @click="sceItemClick(item)">
            <img v-if="item.mapDefaultPic" class="itemleft" style="width: 80px;height: 80px;" :src="item.mapDefaultPic" alt=""/>
            <img v-else class="itemleft" style="width: 80px;height: 80px;" src="./assets/images/defaultGeoPic.png" alt="">
            <div class="itemCont">
              <div class="itemName">{{ item.mapSpotName }}</div>
              <div class="itemDistance" v-show="item.distance">
                距您{{ item.distance | distanceFormat }}
              </div>
            </div>
            <div class="itemRight">
              <div class="ItemNavigator" @click.stop="toThere(item)">
                <div class="iconBox">
                  <img class="navigatIcon" src="./assets/images/navigation.svg" alt=""/>
                </div>
                <span>去这里</span>
              </div>
              <div v-if="item.mapIsVoice" class="voiceExp">
                <div class="iconBox">
                  <img class="voiceExpIcon" src="./assets/images/voiceExp.svg" alt=""/>
                </div>
                <span>讲解</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="contType == 'type1_detail'" class="secIntr">
          <span class="secIntrTitle">景点简介</span>
          <div class="secIntrDet" v-html="secPointDetail.mapDesc"></div>
        </div>
      </div>
    </div>

    <div v-if="spotType == 2" class="contBox" :style="{ overflow: contPosition == 'top' ? 'auto' : 'hidden' }" ref="contBox">
      <controlIcon :contPosition="contPosition"/>
      <exitIcon :contType="contType" @exitDetail="exitDetail"/>
      <div :class="['contBoxTop',contType === 'type2_detail' && !secPointDetail.mapDesc ? 'borRadius10' : '']">
        <div v-if="routeList.length">
          <div v-if="contType == 'type2'" class="traRoutes">
            <div :class="['routeItem', currRouteInfo.routeId == item.routeId ? 'routeItemActive' : '',]" v-for="item in routeList" :key="item.routeId" @click="chooseRoute(item)">
              <div class="routeItemName">{{ item.routeName }}</div>
              <div class="routeItemAbs">
                约{{ (item.routeTravelTime / 60).toFixed(1) }}小时,{{
                item.routeTourSpot.length
                }}个景点
              </div>
            </div>
          </div>
          <div v-if="contType == 'type2_detail'" class="secDetail">
            <div class="secDetailTop">
              <div class="deTopLeft">
                <img v-if="secPointDetail.mapDefaultPic" :src="secPointDetail.mapDefaultPic" alt=""/>
                <img v-else src="./assets/images/defaultGeoPic.png" alt=""/>
              </div>
              <div class="deTopRit">
                <div class="secDeTitle">
                  <span class="s_name">{{ secPointDetail.mapSpotName }}</span>
                  <span class="s_dis" v-show="secPointDetail.distance">{{
                  secPointDetail.distance | distanceFormat
                }}</span>
                </div>
                <div v-if="audioList" class="s_voice">
                  <audio-player
                      ref="audioPlayer"
                      :audio-list="audioList.filter(item => item.voiTypeId === voiTypeRadio).map((item) => item.fileUrl)"
                      theme-color="#f8d466"
                      @play="radioPlay"
                      @pause="radioPause"
                      @ended="radioEnd"
                  />
                </div>
              </div>
            </div>
            <div class="secDetailBot">
              <div class="s_navigator" @click.stop="toThere(secPointDetail)">
                <img class="s_icon" src="./assets/images/navigator_d.svg" alt=""/>
                <span class="s_span">去这里</span>
              </div>
              <div v-if="audioList" class="playerBtn" @click.stop="palyRadio">
                <img v-if="!radioPlaying" class="player_icon" src="./assets/images/radio_play.svg" alt=""/>
                <img v-else class="player_icon" src="./assets/images/radio_pause.svg" alt=""/>
                <span class="player_span">{{ radioPlaying ? "暂停" : "播放" }}讲解</span>
              </div>
            </div>
          </div>
        </div>
        <van-empty v-else image="error" image-size="80" description="未找到相关路线"/>
      </div>
      <div :class="['contBoxCont', contType == 'type2_detail' && !secPointDetail.mapDesc ? 'disNone' : '' ]">
        <div class="routeDetail" v-if="contType == 'type2'">
          <van-steps class="vantSteps" direction="vertical" :active="-1">
            <van-step v-for="(item, index) in currRouteLs" :key="index">
              <template slot="inactive-icon">
                <div class="stepIndex">{{ index + 1 }}</div>
              </template>
              <div class="stepCont" @click="sceItemClick(item)">
                <div class="stepLeft">
                  <img v-if="item.mapDefaultPic" :src="item.mapDefaultPic" alt=""/>
                  <img v-else src="./assets/images/defaultGeoPic.png" alt="">
                </div>
                <div class="stepCent">
                  <div class="stepCentName">{{ item.mapSpotName }}</div>
                  <div class="stepCentDis" v-show="item.distance">
                    距您{{ item.distance | distanceFormat }}
                  </div>
                </div>
                <div class="stepRit">
                  <div class="ItemNavigator" @click.stop="toThere(item)">
                    <div class="iconBox">
                      <img class="navigatIcon" src="./assets/images/navigation.svg" alt=""/>
                    </div>
                    <span>去这里</span>
                  </div>
                  <div class="voiceExp">
                    <div class="iconBox">
                      <img class="voiceExpIcon" src="./assets/images/voiceExp.svg" alt=""/>
                    </div>
                    <span>讲解</span>
                  </div>
                </div>
              </div>
            </van-step>
          </van-steps>
        </div>
        <div v-if="contType == 'type2_detail'" class="secIntr">
          <span class="secIntrTitle">景区简介</span>
          <div class="secIntrDet" v-html="secPointDetail.mapDesc"></div>
        </div>
      </div>
    </div>
    <div v-if="spotType == 3" class="contBox" :style="{ overflow: contPosition == 'top' ? 'auto' : 'hidden' }" ref="contBox">
      <controlIcon :contPosition="contPosition"/>
      <exitIcon :contType="contType" @exitDetail="exitDetail"/>
      <div :class="['contBoxTop', contType === 'type3_detail' || !currMarkerGeo.length ? 'borRadius10' : '']">
        <div v-if="currMarkerGeo.length">
          <div v-if="contType == 'type3'" class="publicSite">
            <div class="publicSiteTotal">为您找到周边{{ currMarkerGeo.length }}个{{navList[active].spotTypeName}}</div>
            <div class="publicSiteTip" v-show="getMinDistance(currMarkerGeo)">
              最近的一个距您{{getMinDistance(currMarkerGeo) | distanceFormat}}
            </div>
          </div>
          <div v-if="contType == 'type3_detail'" class="secDetail">
            <div class="secDetailTop">
              <div class="deTopLeft">
                <img v-if="secPointDetail.mapDefaultPic" :src="secPointDetail.mapDefaultPic" alt=""/>
                <img v-else src="./assets/images/defaultGeoPic.png" alt=""/>
              </div>
              <div class="deTopRit">
                <div class="secDeTitle">
                  <span class="s_name">{{ secPointDetail.mapSpotName }}</span>
                  <span class="s_dis" v-show="secPointDetail.distance">{{secPointDetail.distance | distanceFormat}}</span>
                </div>
                <div v-if="audioList" class="s_voice">
                  <audio-player
                      ref="audioPlayer"
                      :audio-list="audioList.filter(item => item.voiTypeId === voiTypeRadio).map((item) => item.fileUrl)"
                      theme-color="#f8d466"
                      @play="radioPlay"
                      @pause="radioPause"
                      @ended="radioEnd"/>
                </div>
              </div>
            </div>
            <div class="secDetailBot">
              <div class="s_navigator" @click.stop="toThere(secPointDetail)">
                <img class="s_icon" src="./assets/images/navigator_d.svg" alt=""/>
                <span class="s_span">去这里</span>
              </div>
              <div v-if="audioList" class="playerBtn" @click.stop="palyRadio">
                <img v-if="!radioPlaying" class="player_icon" src="./assets/images/radio_play.svg" alt=""/>
                <img v-else class="player_icon" src="./assets/images/radio_pause.svg" alt=""/>
                <span class="player_span">{{ radioPlaying ? "暂停" : "播放" }}讲解</span>
              </div>
            </div>
          </div>
        </div>
        <van-empty v-else image="error" image-size="80" description="未找到相关地点"/>
      </div>
      <div :class="['contBoxCont', contType === 'type3_detail' || !currMarkerGeo.length ? 'disNone' : '']">
        <div class="scenicList" v-if="contType == 'type3'" ref="boxList">
          <div class="scenicItem" @click="sceItemClick(item)" v-for="item in currMarkerGeo" :key="item.id">
            <img v-if="item.properties.mapDefaultPic" class="itemleft" style="width: 80px;height: 80px;" :src="item.properties.mapDefaultPic" alt=""/>
            <img v-else class="itemleft" style="width: 80px;height: 80px;" src="./assets/images/defaultGeoPic.png" alt="">
            <div class="itemCont">
              <div class="itemName">{{ item.properties.mapSpotName }}</div>
              <div class="itemDistance" v-show="item.properties.distance">
                距您{{ item.properties.distance | distanceFormat }}
              </div>
            </div>
            <div class="itemRight" style="justify-content: flex-end">
              <div class="ItemNavigator" @click.stop="toThere(item)">
                <div class="iconBox">
                  <img class="navigatIcon" src="./assets/images/navigation.svg" alt=""/>
                </div>
                <span>去这里</span>
              </div>
              <div v-if="item.mapIsVoice" class="voiceExp">
                <div class="iconBox">
                  <img class="voiceExpIcon" src="./assets/images/voiceExp.svg" alt=""/>
                </div>
                <span>讲解</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="['userSetting', settingBox ? 'rotate1' : 'rotate']" ref="Setting" @click="openSetting">
      <img src="./assets/images/setting.svg" alt=""/>
    </div>
    <van-overlay class-name="vanOverlay" :show="settingBox" @click="settingBox = false" z-index="2000">
      <div :class="['wrapper', 'animate__animated ',settingBox ? 'animate__fadeInRight' : 'animate__fadeOutRight']">
        <div class="settingCont" @click.stop>
          <div class="setContTop">
            <div class="setContTopItem" v-if="isHasPersonLayerId">
              <span class="setItemLeft">手绘地图</span>
              <van-switch v-model="custMapShow" size="16px"/>
            </div>
            <div class="setContTopItem">
              <span class="setItemLeft">显示景点名称</span>
              <van-switch v-model="custSecNameShow" size="16px"/>
            </div>
            <div class="setContTopItem" style="display: block">
              <span class="setItemLeft">语音类型</span>
              <van-radio-group v-model="voiTypeRadio" class="radioGroup">
                <van-radio :name="item.voiTypeId" v-for="(item, index) in voiTypeList" :key="item.voiTypeId">
                  {{item.voiTypeName }}
                  <template #icon="props">
                    <img class="imgIcon" :src="props.checked ? radioIconAcrive : radioIcon"/>
                  </template>
                </van-radio>
              </van-radio-group>
            </div>
            <!--<van-cell class="warnningCell" is-link>
              &lt;!&ndash; 使用 title 插槽来自定义标题 &ndash;&gt;
              <template #title>
                <img
                  class="warnningIcon"
                  src="./assets/images/set_warning.svg"
                  alt=""/>
                <span class="custom-title">一键救援</span>
              </template>
            </van-cell>-->
          </div>
        </div>
      </div>
    </van-overlay>
    <div :class="['userLocation', IsLocCurrPos ? 'checked' : 'noChecked']" @click="locCurrPos">
      <img src="./assets/images/location.svg" alt=""/>
    </div>
  </div>
</template>
<script>
  const radioIcon = require("./assets/images/set_radio.svg");
  const radioIconAcrive = require("./assets/images/set_radioChecked.svg");
  const peopleIcon = require("./assets/images/people.svg");
  import { markerGeoFormat, secIntrFormat, cooFormat, clearMarkerStyleChecked, clearRouteMarkerStyle } from "../utils/util";
  import { Touch, setDom } from "../utils/Touch";
  import {
    Tabs,
    Tab,
    Cell,
    Steps,
    Step,
    Overlay,
    Switch,
    RadioGroup,
    Radio,
    Icon,
    Toast,
    Empty
  } from "vant";
  import AudioPlayer from "./components/AudioPlayer.vue";
  import controlIcon from "./components/controlIcon.vue";
  import exitIcon from "./components/exitIcon.vue";

  export default {
    data() {
      return {
        map: "",
        mapCenter: [],
        isHasPersonLayerId: true, // 是否有个性化图层
        personLayerId: '',
        active: '',
        radioIcon,
        radioIconAcrive,
        voiTypeRadio: "1",
        voiTypeList: [],
        contPosition: "bot",
        contType: "type1",
        radioPlaying: false,
        settingBox: false, //设置弹出
        maskId: null, //手绘地图遮罩层id
        mask: null, //地图自定义覆盖层
        markerList: [], //地图上所有的点位信息
        iconList: [], //地图上所有的景点图标集
        multiLabelList: [], //所有标点窗口信息集合
        label: '',
        maskPaths: [],
        mapStyleId: '', // 地图背景图层id
        custMapShow: true, //是否显示手绘地图
        custSecNameShow: true, //是否显示景点名称
        myLocation: {},
        secInfo: {}, //景区信息
        navList: [], //导航列表
        secPointList: [], //所有景点列表
        routeList: [], //所有路线列表
        currRouteInfo: {}, //当前浏览路线总信息
        currRouteLs: [], //当前浏览路线景点列表
        secPointDetail: {}, //景点详情
        audioList: [],
        currMarkerGeo: [], //当前显示的marker Geo集合
        allPointInfo: [], //所有点位集合
        spotType: 1, //当前景点类型 1景点 2路线 3公共设施
        minDistance: '',
        IsLocCurrPos: false,
        routeStyles: '',
        styles: ''
      };
    },
    computed: {
      routePointObj() {
        let obj = {};
        for (let i = 1; i <= 60; i++) {
          obj[`point${i}`] = require(`./assets/imgs/point_${i}.png`);
        }
        return obj;
      },
      isInside() {
        if (this.currLocation) {
          return this.isContains(this.currLocation);
        } else {
          return false;
        }
      }
    },
    components: {
      AudioPlayer,
      controlIcon,
      exitIcon,
      [Icon.name]: Icon,
      [Tabs.name]: Tabs,
      [Tab.name]: Tab,
      [Cell.name]: Cell,
      [Steps.name]: Steps,
      [Step.name]: Step,
      [Overlay.name]: Overlay,
      [RadioGroup.name]: RadioGroup,
      [Radio.name]: Radio,
      [Toast.name]: Toast,
      [Empty.name]: Empty,
      [Switch.name]: Switch
    },
    filters: {
      distanceFormat(e) {
        if (e < 1000) return e + "m";
        return (e / 1000).toFixed(1) + "km";
      }
    },
    watch: {
      //是否展示手绘地图
      custMapShow(n) {
        this.customLayer.setVisible(n);
        this.mask.visible = n;
      },
      voiTypeRadio(n) {
        this.radioPlaying = false
        localStorage.setItem("voiTypeRadioId", n)
      },
      //是否展示景点名称
      custSecNameShow(n) {
        this.label.setGeometries(this.multiLabelList.filter(item => {return this.currMarkerGeo.some(ele => ele.id == item.id) && n}))
      },
      currMarkerGeo: {
        handler(n, o) {
          if (this.spotType == 2) {
            this.markers.setGeometries([]);
            this.routeMarkers.setGeometries(n);
          } else {
            this.markers.setGeometries(n);
            this.routeMarkers.setGeometries([]);
          }
          this.label.setGeometries(this.multiLabelList.filter(item => n.some(ele => ele.id == item.id) && this.custSecNameShow))
        },
        deep: true
      },
      active(n) {
        this.spotType = this.navList[n].spotType;
        if (this.navList[n].spotType == 2) {
          clearRouteMarkerStyle(this.routeStyles)
          this.currMarkerGeo = markerGeoFormat(this.navList[n].tourMapList);
          this.currRouteInfo ? this.drawRoute(this.currRouteInfo) : '';
        } else {
          clearMarkerStyleChecked(this.markers, this.navList[n].tourMapList)
          this.currRouteInfo ? this.polylineLayer.remove(this.currRouteInfo.routeId) : '';
          this.currMarkerGeo = markerGeoFormat(this.navList[n].tourMapList);
        }
      },
      audioList: {
        handler(n) {
          this.radioPlaying = false;
        },
        deep: true
      },
    },
    methods: {
      //获取景区信息
      getSecInfo() {
        this.$post(this.$api.apiGetSecInfo).then(res => {
          this.secInfo = res.data[0];
        });
      },
      //获取所有景点信息
      getSecList() {
        this.$post(this.$api.apiGetSecList).then(res => {
          this.navList = res.items;
          this.iconList = res.icon;
          this.voiTypeList = res.voiceTypes;
          res.items.forEach(item => {
            if (item.spotType == 2) {
              this.routeList = item.mapRouteList;
              this.currRouteInfo = this.routeList[0];
            } else {
              this.allPointInfo = this.allPointInfo.concat(item.tourMapList);
              if (item.spotType == 1) {
                this.secPointList = item.tourMapList;
                this.secPointList.forEach(item => {
                  item.mapDesc = secIntrFormat(item.mapDesc);
                });
              }
            }
          });
          // 这里获取语音选项全局配置
          let voiTypeRadioId = parseInt(localStorage.getItem("voiTypeRadioId")) || -1
          let voiTypeIdList = this.voiTypeList.map(item => item.voiTypeId)
          if (voiTypeIdList.indexOf(voiTypeRadioId) > -1) {
            this.voiTypeRadio = voiTypeRadioId
          } else {
            this.voiTypeRadio = voiTypeIdList[0]
          }
          this.initMap();
        });
      },
      //点击景点列表到景点详情
      sceItemClick(e) {
        let flag = this.spotType != 3 ? true : false;
        if (this.navList[this.active].spotType == 2) {
          for (let i in this.routeStyles) {
            this.routeStyles[i]['width'] = 30
            this.routeStyles[i]['height'] = 30
            this.routeStyles[i]['anchor'] = {x: 14, y: 28}
          }
          let arr = [];
          this.currRouteInfo.routeTourSpot.forEach(item => {
            arr.push(this.allPointInfo.filter(ele => ele.mapId == item)[0]);
          });
          let eArrIndex = arr.map(item => item.mapId).indexOf(e.mapId)
          this.routeStyles[`point${eArrIndex + 1 }`]['width'] = 40
          this.routeStyles[`point${eArrIndex + 1 }`]['height'] = 40
          this.routeStyles[`point${eArrIndex + 1 }`]['anchor'] = {x: 18, y: 36}
          this.currMarkerGeo = markerGeoFormat(arr, true);
        } else {
          let mapIconStoId = flag ? e.mapIconStoId : e.properties.mapIconStoId
          let mapId = flag ? e.mapId : e.properties.mapId
          for (let i in this.styles) {
            if (/_a$/g.test(this.styles[i])) {
              delete this.styles[i]
            }
          }
          this.navList[this.active].tourMapList.forEach(item => {
            if (/_a$/g.test(item.mapIconStoId)) {
              item.mapIconStoId = item.mapIconStoId.replace(/_a$/g, '')
            }
          })
          if (!/_a$/g.test(e.mapIconStoId)) {
            this.styles[`${mapIconStoId}_a`] = JSON.parse(JSON.stringify(flag ? this.styles[mapIconStoId] : this.styles[mapIconStoId]))
            this.styles[`${mapIconStoId}_a`]['width'] = 40
            this.styles[`${mapIconStoId}_a`]['height'] = 40
            this.styles[`${mapIconStoId}_a`]['anchor'] = {x: 18, y: 36}
            this.navList[this.active].tourMapList.forEach(item => {
              if (item.mapId == mapId) {
                item.mapIconStoId = `${mapIconStoId}_a`
              }
            })
          }
          this.currMarkerGeo = markerGeoFormat(this.navList[this.active].tourMapList);
        }
        this.height = document.body.clientHeight * 0.25;
        this.y = document.body.clientHeight * 0.75;
        this.direction = "toBottom";
        setDom("end");
        this.contType = `${this.contType}_detail`;
        this.secPointDetail = flag ? e : e.properties;
        this.audioList = flag ? e.mapVoiceList : null;
        this.map.easeTo(
            { center: flag ? cooFormat(e.mapCoordinate) : e.position },
            { duration: 300 }
        );
      },
      chooseRoute(info) {
        //点击路线显示详情
        clearRouteMarkerStyle(this.routeStyles)
        this.polylineLayer.remove(this.currRouteInfo.routeId);
        this.currRouteInfo = info;
        this.drawRoute(info);
      },
      //浏览路线画线
      drawRoute(e) {
        let arr = [];
        e.routeTourSpot.forEach(item => {
          arr.push(this.allPointInfo.filter(ele => ele.mapId == item)[0]);
        });
        this.currRouteLs = arr;
        this.currMarkerGeo = markerGeoFormat(arr, true);
        let paths = cooFormat(e.routePointCoordinate);
        let geoLs = [
          {
            id: e.routeId,
            styleId: "style_blue", //绑定样式名
            paths
          }
        ];
        this.polylineLayer.add(geoLs);
      },
      //创建画线图层
      createRouteLayer() {
        this.polylineLayer = new TMap.MultiPolyline({
          id: "polyline-layer", //图层唯一标识
          map: this.map, //绘制到目标地图
          //折线样式定义
          styles: {
            style_blue: new TMap.PolylineStyle({
              color: "#ff7575", //线填充色
              width: 4, //折线宽度
              // borderWidth: 2, //边线宽度
              // borderColor: "#FFF", //边线颜色
              lineCap: "round" //线端头方式
            })
          }
        });
      },
      locCurrPos() {
        this.IsLocCurrPos = true
        this.getCurrLoc().then(res => {
          this.calcDis(res);
          if (this.mapRange.contains(cooFormat(res))) {
            //判断当前设备定位是否在景区内
            this.map.easeTo({ center: cooFormat(res) }, { duration: 300 });
            let geo = [{ position: cooFormat(res), styleId: "peoStyle" }];
            this.peoMarker.setGeometries(geo);
          } else {
            Toast("您当前不在景区内");
            /*let geo = [
              {
                position: this.mapCenter,
                styleId: "peoStyle"
              }
            ];
            this.peoMarker.setGeometries(geo);*/
          }
        }).catch(err => {});
        setTimeout(() => {
          this.IsLocCurrPos = false
        }, 300)
      },
      openSetting() {
        this.settingBox = !this.settingBox;
      },
      //点击切换navBar
      tabClick(e) {
        this.spotType = this.navList[e].spotType;
        this.contType = `type${this.spotType}`;
        this.radioPlaying = false
      },
      exitDetail() {
        this.contType = this.contType.split("_")[0];
        this.radioPlaying = false
        if (this.navList[this.active].spotType == 2) {
          clearRouteMarkerStyle(this.routeStyles)
          this.drawRoute(this.currRouteInfo)
        } else {
          clearMarkerStyleChecked(this.markers, this.navList[this.active].tourMapList)
          this.currMarkerGeo = markerGeoFormat(this.navList[this.active].tourMapList)
        }
      },
      toThere(e) {
        let { mapCoordinate, mapSpotName } = e.properties ? e.properties : e;
        let arr = [];
        if (typeof mapCoordinate == "string") {
          arr = mapCoordinate.split(",");
        } else {
          arr = mapCoordinate;
        }
        parent.wx.openLocation({
          latitude: Number(arr[0]), // 纬度，浮点数，范围为90 ~ -90
          longitude: Number(arr[1]), // 经度，浮点数，范围为180 ~ -180。
          name: mapSpotName // 位置名
        });
      },
      palyRadio() {
        // this.customLayer(localStorage.getItem('customLayerVisible'))
        if (this.radioPlaying) {
          this.$refs.audioPlayer.pause();
        } else {
          this.$refs.audioPlayer.play();
        }
      },
      radioPlay() {
        this.radioPlaying = true;
      },
      radioPause() {
        this.radioPlaying = false;
      },
      radioEnd() {
        this.$refs.audioPlayer.handleClickProgressWrap();
      },
      initMap() {
        this.maskId = "mask-layer";
        var center = this.mapCenter
        var map = new window.TMap.Map(document.getElementById("map"), {
          pitch: 22, //倾斜角度
          center: center, // 设置地图中心点坐标
          zoom: 18, // 设置地图缩放级别，胡里山炮台
          // zoom: 16, // 设置地图缩放级别，碛口风景名胜区
          viewMode: "2D",
          baseMap: {
            type: 'vector',
            features: ['base', 'building3d']  // 隐藏矢量文字
          }
        });
        this.mapStyleId ? map.setMapStyleId(this.mapStyleId) : ''
        this.map = map;
        //创建自定义覆盖 隐藏地图自带的坐标位置
        this.mask = new window.TMap.MaskLayer({
          map: map,
          geometries: []
        });
        if (this.maskPaths.length > 0) {
          this.mask.add([
            {
              id: this.maskId,
              paths: this.maskPaths
            }
          ]);
          //创建地图景区范围  后续用于判断当前设备是否在景区
          this.mapRange = new window.TMap.LatLngBounds(
              this.maskPaths[1],
              this.maskPaths[3]
          );
        }
        //去除地图自带的控件 缩放/比例尺/rotate等
        const control = window.TMap.constants.DEFAULT_CONTROL_ID;
        for (let i in control) {
          map.removeControl(control[i]);
        }
        this.active = 0
        this.wxConfigInit();
        this.creatMapLayer();
        this.createMarkers();
        this.creatInfoWindow();
        this.createRouteLayer();
      },
      //地图添加标记点
      createMarkers() {
        const _this = this;
        let styles = {};
        let routeStyles = {};
        let peoStyle = {
          peoStyle: new window.TMap.MarkerStyle({
            width: 26,
            height: 26,
            anchor: { x: 14, y: 28 },
            src: peopleIcon
          })
        };
        for (let i in this.iconList) {
          styles[i] = new window.TMap.MarkerStyle({
            width: 30,
            height: 30,
            anchor: { x: 14, y: 28 },
            src: this.iconList[i]
          });
        }
        this.styles = styles
        for (let i in this.routePointObj) {
          routeStyles[i] = new window.TMap.MarkerStyle({
            width: 30,
            height: 30,
            anchor: { x: 14, y: 28 },
            src: this.routePointObj[i]
          });
        }
        this.routeStyles = routeStyles
        var markers = new window.TMap.MultiMarker({
          id: "marker-layer",
          map: _this.map,
          styles,
          geometries: []
        });
        var routeMarkers = new window.TMap.MultiMarker({
          id: "routeMarker-layer",
          map: _this.map,
          styles: routeStyles,
          geometries: []
        });
        var peoMarker = new window.TMap.MultiMarker({
          id: "peoMarker-layer",
          map: _this.map,
          styles: peoStyle,
          geometries: []
        });
        this.markers = markers;
        this.routeMarkers = routeMarkers;
        this.peoMarker = peoMarker;

        this.markers.on("click", function(evt) {
          if (!/_a$/g.test(evt.geometry.styleId)) {
            for (let i in styles) {
              if (/_a$/g.test(styles[i])) {
                delete styles[i]
              }
            }
            _this.navList[_this.active].tourMapList.forEach(item => {
              if (/_a$/g.test(item.mapIconStoId)) {
                item.mapIconStoId = item.mapIconStoId.replace(/_a$/g, '')
              }
            })
            styles[`${evt.geometry.styleId}_a`] = JSON.parse(JSON.stringify(styles[evt.geometry.styleId]))
            styles[`${evt.geometry.styleId}_a`]['width'] = 40
            styles[`${evt.geometry.styleId}_a`]['height'] = 40
            styles[`${evt.geometry.styleId}_a`]['anchor'] = {x: 18, y: 36}
            evt.geometry.styleId = `${evt.geometry.styleId}_a`
            _this.navList[_this.active].tourMapList.forEach(item => {
              if (item.mapId == evt.geometry.id) {
                item.mapIconStoId = evt.geometry.styleId
              }
            })
          }
          _this.currMarkerGeo = markerGeoFormat(_this.navList[_this.active].tourMapList);
          _this.markerEvent(evt);
        });

        this.routeMarkers.on("click", function(evt) {
          for (let i in routeStyles) {
            routeStyles[i]['width'] = 30
            routeStyles[i]['height'] = 30
            routeStyles[i]['anchor'] = {x: 14, y: 28}
          }
          routeStyles[evt.geometry.styleId]['width'] = 40
          routeStyles[evt.geometry.styleId]['height'] = 40
          routeStyles[evt.geometry.styleId]['anchor'] = {x: 18, y: 36}
          let arr = [];
          _this.currRouteInfo.routeTourSpot.forEach(item => {
            arr.push(_this.allPointInfo.filter(ele => ele.mapId == item)[0]);
          });
          _this.currMarkerGeo = markerGeoFormat(arr, true);
          _this.markerEvent(evt);
        });
      },
      markerEvent(evt) {
        // console.log(evt)
        if (!this.contType.includes("detail")) {
          this.contType = `${this.contType}_detail`;
        }
        this.secPointDetail = evt.geometry.properties;
        this.audioList = this.secPointDetail.mapVoiceList;
        // var lat = evt.latLng.getLat()
        // var lng = evt.latLng.getLng()
        // this.map.easeTo(
        //   { center: new window.TMap.LatLng(lat, lng) },
        //   { duration: 300 }
        // );
      },
      //生成标点下方文字
      creatInfoWindow() {
        this.allPointInfo.forEach(item => {
          let obj = {
            id: item.mapId,
            styleId: 'label',
            position: cooFormat(item.mapCoordinate),
            content: item.mapSpotName,
          };
          this.multiLabelList.push(obj);
        });
        let label = new window.TMap.MultiLabel({
          id: 'label-layer',
          map: this.map,
          styles: {
            'label': new window.TMap.LabelStyle({
              'color': '#ffffff', //景点文字颜色，胡里山炮台
              // 'color': '#613909', //景点文字颜色，碛口风景名胜区
              'size': 14, //文字大小属性
              'offset': { x: 0, y: 12 }, //文字偏移属性单位为像素
              'angle': 0, //文字旋转属性
              'alignment': 'center', //文字水平对齐属性
              'verticalAlignment': 'middle' //文字垂直对齐属性
            })
          }
        })
        this.label = label
      },
      //创建自定义图层
      creatMapLayer() {
        const _this = this;
        if (this.personLayerId) {
          new window.TMap.ImageTileLayer.createCustomLayer({
            layerId: this.personLayerId,
            map: this.map
          }).then(customLayer => {
            if (customLayer) {
              //设置图层可见
              customLayer.setVisible(_this.custMapShow);
              _this.mask.visible = _this.custMapShow;
              _this.customLayer = customLayer;
            } else {
              // 创建失败，请查看控制台错误信息
            }
          });
        }
      },
      wxConfigInit() {
        let _this = this;
        //this.$post(this.$api.apiGetAppKey).then(res => {
          /*wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            beta: true,
            appId: res.appid, // 必填，公众号的唯一标识
            timestamp: res.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.noncestr, // 必填，生成签名的随机串
            signature: res.sign, // 必填，签名
            jsApiList: ["openLocation", "getLocation"] // 必填，需要使用的JS接口列表
          });*/
          parent.wx.ready(function() {
            // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
            _this.getCurrLoc().then(res => {
              _this.currLocation = res;
              if (_this.mapRange.contains(cooFormat(res))) {
                /*Toast("初次判断，在景区");*/
              } else {
                Toast("您当前不在景区内");
                /*let geo = [
                  {
                    position: this.mapCenter,
                    styleId: "peoStyle"
                  }
                ];
                _this.peoMarker.setGeometries(geo);*/
              }
              _this.calcDis(res).then(response=>{
                if(response){
                  _this.secPointList.length > 1 ?_this.secPointList.sort((a, b) =>  a.distance - b.distance) : ''
                  _this.currMarkerGeo = markerGeoFormat(_this.secPointList)
                }
              }).catch(err => {});
            }).catch(err => {});
          });
        // });
      },
      //获取当前设备定位
      getCurrLoc() {
        return new Promise((res, rej) => {
          parent.wx.getLocation({
            type: "gcj02", // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
            altitude: true,
            /*isHighAccuracy: true, // 高精度模式*/
            success: function(response) {
              let { latitude, longitude } = response;
              res(`${latitude},${longitude}`);
            },
            fail(err) {
              rej(err);
            }
          });
        }).catch(err => {});
      },
      //计算各景点与设备定位的距离
      calcDis(mapFrom) {
        return new Promise((resolve, reject) => {
          let mapTo = this.allPointInfo.map(item => item.mapCoordinate).join(";");
          let data = {
            mapMode: "walking",
            mapFrom,
            mapTo
          };
          this.$post(this.$api.apiCalcDistance, data).then(res => {
            if (!res.status) {
              let elements = res.result.rows[0].elements;
              this.allPointInfo.forEach((item, index) => {
                item.distance = elements[index].distance;
              });
              this.secPointList.forEach(item => {
                item.distance = this.allPointInfo.filter(
                    ele => ele.mapId == item.mapId
                )[0].distance;
                resolve(true)
              });
            } else {
              reject('参数错误')
            }
          }).catch(err => {});
        }).catch(err => {});
      },
      getMinDistance(currMarkerGeo) {
        return currMarkerGeo[0].properties.distance
      },
      handlePhone () {
        window.location.href = 'tel:' + this.secInfo.scenicField
      },
    },
    mounted() {
      document.domain = 'ezhouxishan.com'
      this.$post(this.$api.apiGetAppKey).then(res => {
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          beta: true,
          appId: res.appid, // 必填，公众号的唯一标识
          timestamp: res.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.noncestr, // 必填，生成签名的随机串
          signature: res.sign, // 必填，签名
          jsApiList: ["openLocation", "getLocation"] // 必填，需要使用的JS接口列表
        })
      })

      this.$get(this.$api.apiGetMapConfig).then(res => {
        if (!res.status) {
          let key = 'https://map.qq.com/api/gljs?v=1.exp&key=' + res.mapKey
          let script = document.createElement("script")
          script.type = "text/javascript"
          script.src = key
          document.head.appendChild(script)
          this.personLayerId = res.personLayerId || ''
          if (this.personLayerId === '') {
            this.isHasPersonLayerId = false
          }
          setTimeout(() => {
            if (res.upperLeft && res.lowerLeft && res.lowerRight && res.upperRight ) {
              this.maskPaths = [
                //s地图自定义覆盖区域坐标 逆时针各角坐标
                cooFormat(res.upperLeft), //左上
                cooFormat(res.lowerLeft), //左下
                cooFormat(res.lowerRight), //右下
                cooFormat(res.upperRight) //右上
              ]
            }
            this.mapCenter = cooFormat(res.centerSpot)
            this.mapStyleId = res.mapStyleId
            this.getSecInfo();
            this.getSecList();
            Touch(
                {
                  contEl: this.$refs.app, //背景灰色浮层
                  shadowEl: this.$refs.contBox, //内容块
                  vanTabs: this.$refs.vanTabs,
                  Setting: this.$refs.Setting,
                  top: 0.1, //内容块停留的最高处 (取值范围：0.1-1,默认0.1)
                  bottom: 0.81, //内容块停留的最低处 (取值范围：0.1-1,默认0.9)
                  duration: 0.3 //松手时候下滑或上滑动画的持续时间 (单位：秒,默认0.3)
                },
                this
            );
          }, 800)
        }
      })
      // 禁用双指放大
      document.documentElement.addEventListener('touchstart', function (event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      }, {
        passive: false
      })
    },
  };
</script>
<style lang="scss">
  * {
    margin: 0;
    padding: 0;
    /*font-family: Microsoft YaHei;*/
    box-sizing: border-box;
    letter-spacing: 0.5px;
  }

  img {
    width: 100%;
    height: 100%;
    vertical-align: middle;
    color: #222222;
  }

  .animate__animated {
    animation-duration: 0.3s !important; //动画持续时间
  }

  html,
  body,
  #app {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  ::-webkit-scrollbar {
    display: none;
    /* 解决ios手机页面滑动卡顿问题 */
    -webkit-overflow-scrolling: touch;
  }

  .info_card {
    display: flex;
    text-align: center;
    position: absolute;
    top: 18px;
    line-height: 1.4;
    padding: 0 8px;
    background: rgba(255, 255, 255, 0.9);
    font-size: 10px;
    border-radius: 4px;
    color: #63412d;
    border: 1px solid #63412d;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  }

  #app {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    .vanTabsDiv {
      width: 100%;
      height: 40px;
      overflow: hidden;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1001;
    }

    .vanTabs {
      height: 40px;

      .van-tabs__wrap {
        height: 40px;

        .tabActive {
          text-align: center;
          font-weight: bold;
        }
      }

      .van-tabs__line {
        width: 24px;
        height: 4px;
        border-radius: 5px;
        bottom: 18px;
      }
    }

    #map {
      flex: 1;
      width: 100%;
      height: 100%;
    }

    .userSetting {
      position: absolute;
      top: 50px;
      right: 10px;
      z-index: 2001;
      width: 40px;
      height: 40px;
      background: #fff;
      border-radius: 50%;
      padding: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 3px 6px 10px -5px rgba(0, 0, 0, 0.3);

      img {
        width: 20px;
        height: 20px;
      }
    }

    .rotate {
      transform: rotate(0) !important;
      transition: 0.3s linear !important;
    }

    .rotate1 {
      transform: rotate(90deg) !important;
      transition: 0.3s linear !important;
    }

    .wrapper {
      height: 100%;
      position: relative;
      z-index: 2000;
    }

    .settingCont {
      position: absolute;
      left: 50%;
      top: 15%;
      transform: translateX(-50%);
      width: 70%;
      background: #fff;
      border-radius: 10px;
      padding: 20px;
      z-index: 2021;

      .setContTop {
        .setContTopItem {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 20px;

          .setItemLeft {
            font-size: 14px;
            color: #333;
          }

          .van-switch__node {
            width: 0.9em;
            height: 0.9em;
            top: 0.04em;
            left: 0.05em;
            background: #B6BAC2;
            box-shadow: none;
          }

          .van-switch {
            border: 2px solid #777777;
          }

          .van-switch--on {
            background: #fff;

            .van-switch__node {
              background: #07c160;
            }
          }

          .radioGroup {
            display: flex;
            align-items: center;
            padding: 8px 0 5px 0;

            .van-radio {
              margin-right: 10px;

              .van-radio__icon {
                width: 18px;
                height: 18px;
                display: flex;
                justify-content: center;
                align-items: center;

                .imgIcon {
                  width: 100%;
                  height: 100%;
                }
              }

              .van-radio__label {
                margin: 0;
                padding: 0 10px 0 4px;
                font-size: 13px;
                color: #999;
              }
            }
          }
        }

        .warnningCell {
          padding: 0;
          margin-bottom: 10px;

          .van-cell__title {
            display: flex;
            align-items: center;

            .warnningIcon {
              width: 20px;
              height: 20px;
              margin-right: 5px;
            }

            .custom-title {
              color: #555;
            }
          }
        }
      }
    }

    .vanOverlay {
      background: rgba(0, 0, 0, 0.5);
    }

    .contBox {
      width: 96%;
      height: 10%;
      position: absolute;
      top: 80%;
      left: 2%;
      z-index: 1001;
      display: flex;
      flex-direction: column;
      border-radius: 10px;

      .contBoxTop {
        background: #fff;
        border-radius: 10px 10px 0 0;
        position: relative;

        .secListInfo {
          //height: 19vh;
          //padding: 20px 20px 0 20px;
          padding: 20px;

          .scenicTitle {
            display: flex;
            align-items: center;

            .scenicName {
              font-size: 18px;
              font-weight: 600;
              padding-right: 10px;
            }

            .scenicTag {
              padding: 4px 10px;
              font-size: 12px;
              line-height: 1;
              font-weight: bold;
              background: #FFCA27;
              border-radius: 20px;
            }
          }

          .scenicAbs {
            padding-top: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .scenicAbsLeft {
              width: 75%;

              .absLeftItem {
                display: flex;
                align-items: center;
                padding-top: 5px;

                &:not(:first-child) {
                  padding-top: 10px;
                }

                img {
                  width: 12px;
                  height: 12px;
                }

                span {
                  font-size: 12px;
                  padding-left: 5px;
                  color: #999;
                }
              }
            }

            .scenicAbsRight {
              flex: 1;
              padding-right: 10px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-end;

              .absRightCont {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                img {
                  width: 14px;
                  height: 14px;
                }

                span {
                  padding-top: 5px;
                  font-size: 12px;
                  color: #777777;
                }
              }
            }
          }
        }

        .secDetail {
          //height: 19vh;
          //padding: 20px 20px 0 20px;
          padding: 20px;

          .secDetailTop {
            display: flex;

            .deTopLeft {
              width: 60px;
              height: 60px;
              border-radius: 5px;
              overflow: hidden;
            }

            .deTopRit {
              flex: 1;
              overflow: hidden;
              padding-left: 20px;
              display: flex;
              flex-direction: column;
              justify-content: center;

              .secDeTitle {
                display: flex;
                align-items: center;
                .s_name {
                  font-size: 17px;
                  font-weight: 600;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }

                .s_dis {
                  margin-left: 3px;
                  padding: 3px 8px;
                  background: #f0f0f0;
                  border-radius: 15px;
                  font-size: 12px;
                  color: #a7a7a7;
                }
              }
            }
          }

          .secDetailBot {
            padding-top: 5px;
            display: flex;
            justify-content: center;

            .s_navigator {
              flex: 1;
              padding: 8px 14px;
              background: #f2f3f6;
              border-radius: 20px;
              display: flex;
              justify-content: center;
              align-items: center;

              .s_icon {
                width: 16px;
              }

              .s_span {
                color: #07c160;
                font-size: 14px;
                padding-left: 5px;
                font-weight: bold;
              }
            }

            .playerBtn {
              position: relative;
              z-index: 15454455;
              width: 65%;
              background: #f8d466;
              color: #45484a;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 20px;
              margin-left: 10px;

              .player_icon {
                width: 18px;
                height: 18px;
              }

              .player_span {
                font-size: 14px;
                padding-left: 5px;
                font-weight: bold;
              }
            }
          }
        }

        .traRoutes {
          //height: 19vh;
          //padding: 20px 20px 0 20px;
          padding: 20px;
          display: flex;
          justify-content: space-between;
          overflow-x: auto !important;
          flex-wrap: nowrap;
          touch-action: pan-x;

          .routeItem {
            flex-shrink: 0;
            padding: 10px 15px;

            .routeItemName {
              font-size: 18px;
              color: #333;
            }

            .routeItemAbs {
              font-size: 13px;
              color: #969799;
              padding-top: 10px;
            }
          }

          .routeItemActive {

            .routeItemName,
            .routeItemAbs {
              color: #07c160 !important;
            }
          }

          .routeItem:nth-child(1) {
            padding-left: 0;
          }

          .traRouteTitle {
            font-size: 16px;
            font-weight: 600;
          }

          .traRouteTip {
            font-size: 12px;
            color: #969799;
            padding-top: 5px;
          }
        }

        .routeDetTitle {
          padding: 25px 16px;

          .routeName {
            font-size: 16px;
            font-weight: 600;
            color: #292929;
          }

          .routeTip {
            font-size: 12px;
            color: #969799;
            padding-top: 5px;

            span {
              padding: 0 8px;
              position: relative;
            }

            span::after {
              content: " ";
              width: 0;
              height: 8px;
              position: absolute;
              top: 4px;
              left: 0;
              display: block;
              line-height: 1;
              border-left: 1px solid #bdbdbd;
            }

            span:first-child {
              padding-left: 0;
            }

            span:first-child::after {
              border: none;
            }
          }
        }

        .publicSite {
          //height: 19vh;
          //padding: 20px 20px 0 20px;
          padding: 20px;

          .publicSiteTotal {
            font-size: 18px;
            color: #333;
            padding: 10px 0;
          }

          .publicSiteTip {
            font-size: 13px;
            color: #969799;
            padding-top: 10px;
          }
        }
      }

      .borRadius10 {
        border-radius: 10px;
      }

      .contBoxCont {
        flex: 1;
        display: flex;
        flex-direction: column;
        // overflow: hidden;
        // margin-top: 10px;
        // border-radius: 10px 10px 0 0;
        padding-top: 25px;
        padding-bottom: constant(safe-area-inset-bottom);
        padding-bottom: env(safe-area-inset-bottom);
        background: #fff;
        border-top: 1px solid #ebedf0;

        .scenicList {
          flex: 1;
          padding: 0 20px;
          background: #fff;

          .scenicItem {
            display: flex;
            margin-bottom: 25px;
            align-items: center;

            .itemleft {
              border-radius: 5px;
              overflow: hidden;
            }

            .itemCont {
              flex: 1;
              overflow: hidden;
              padding-left: 10px;

              .itemName {
                color: #333;
                font-size: 16px;
                font-weight: 600;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }

              .itemDistance {
                padding-top: 5px;
                font-size: 12px;
                color: #676767;
                letter-spacing: 1px;
              }
            }

            .itemRight {
              width: 80px;
              display: flex;
              justify-content: space-between;
              align-items: center;

              .ItemNavigator {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;

                .iconBox {
                  background: #ededed;
                }

                span {
                  padding-top: 5px;
                  font-size: 10px;
                  color: #676767;
                }
              }

              .voiceExp {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;

                .iconBox {
                  background: linear-gradient(to right bottom, #FFCA27, #f8a23f);
                }

                span {
                  padding-top: 5px;
                  font-size: 10px;
                  color: #676767;
                }
              }

              .iconBox {
                width: 25px;
                height: 25px;
                padding: 5px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }

        .travelRouteList {
          .cellLabel {
            span {
              padding: 0 8px;
              position: relative;
            }

            span::after {
              content: " ";
              width: 0;
              height: 8px;
              position: absolute;
              top: 4px;
              left: 0;
              display: block;
              line-height: 1;
              border-left: 1px solid #bdbdbd;
            }

            span:first-child {
              padding-left: 0;
            }

            span:first-child::after {
              border: none;
            }
          }
        }

        .routeDetail {
          overflow-y: scroll;

          .vantSteps {
            padding: 0 0 0 40px;

            .van-step {
              padding: 0 10px;
              margin-bottom: 25px;
              border-bottom: 0;

              .van-step__title {
                display: flex;

                .stepCont {
                  flex: 1;
                  display: flex;
                  align-items: center;
                  overflow: hidden;
                  padding-right: 20px;

                  .stepLeft {
                    width: 80px;
                    height: 80px;
                    border-radius: 5px;
                    overflow: hidden;
                  }

                  .stepCent {
                    flex: 1;
                    overflow: hidden;
                    padding-left: 10px;

                    .stepCentName {
                      color: #333;
                      font-size: 16px;
                      font-weight: 600;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    }

                    .stepCentDis {
                      padding-top: 5px;
                      font-size: 12px;
                      color: #676767;
                    }
                  }

                  .stepRit {
                    width: 80px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .ItemNavigator {
                      display: flex;
                      flex-direction: column;
                      justify-content: space-between;
                      align-items: center;

                      .iconBox {
                        background: #ededed;
                      }

                      span {
                        padding-top: 5px;
                        font-size: 10px;
                        color: #676767;
                      }
                    }

                    .voiceExp {
                      display: flex;
                      flex-direction: column;
                      justify-content: space-between;
                      align-items: center;

                      .iconBox {
                        background: linear-gradient(
                                        to right bottom,
                                        #FFCA27,
                                        #f8a23f
                        );
                      }

                      span {
                        padding-top: 5px;
                        font-size: 10px;
                        color: #676767;
                      }
                    }

                    .iconBox {
                      width: 25px;
                      height: 25px;
                      padding: 5px;
                      border-radius: 50%;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    }
                  }
                }
              }

              .van-step__circle-container {
                top: 50%;

                .stepIndex {
                  width: 22px;
                  height: 22px;
                  border-radius: 50%;
                  border: 1px solid #07c160;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background: #fff;
                  color: #07c160;
                }
              }

              .van-step__line {
                top: 75%;
                left: -16px;
                width: 0;
                height: 80%;
                border-left: 3px dotted #EDEDF0;
                background: none;
              }
            }

            .van-step:nth-last-child(1) {
              .van-step__line {
                border: none !important;
              }
            }

            .van-hairline::after {
              border: 0 !important;
            }
          }
        }

        .secIntr {
          padding: 0 20px;

          .secIntrTitle {
            font-size: 16px;
            font-weight: 600;
            position: relative;
          }

          .secIntrTitle::after {
            content: " ";
            position: absolute;
            left: -5%;
            bottom: 0;
            width: 110%;
            height: 8px;
            border-radius: 1px;
            background: rgba(255, 191, 0, 0.4);
          }

          .secIntrDet {
            padding-top: 10px;
            font-size: 15px;
            color: #818181;
            letter-spacing: 2px;
            line-height: 2;

            .secIntrItem {
              text-indent: 2em;
            }
          }
        }
      }

      .disNone {
        display: none;
      }
    }

    .userLocation {
      position: absolute;
      left: 4%;
      top: calc(65% - 40px - 10px);
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      z-index: 1000;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 3px 6px 10px -5px rgba(0, 0, 0, 0.5);

      img {
        width: 22px;
        height: 22px;
      }
    }

    .checked {
      background: #ebedf0;
    }

    .noChecked {
      background: #fff;
    }

    .audio-player {
      .audio__progress-point {
        width: 6px;
        height: 6px;
        border-radius: 3px;
        left: -3px;
        top: calc(50% - 3px);
        margin-top: 0;
      }

      .audio__progress-wrap {
        background: #F0F2F5;
      }
    }
  }
</style>
