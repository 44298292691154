import Vue from 'vue'
import App from './App.vue'
import { VueJsonp } from 'vue-jsonp'
import 'vant/lib/index.css';
import { get, post } from '../utils/request'
import api from '../utils/api'
import animated from 'animate.css'
import wx from 'weixin-js-sdk'


Vue.use(animated)
Vue.use(VueJsonp)

Vue.config.productionTip = false
Vue.prototype.$get = get
Vue.prototype.$post = post
Vue.prototype.$api = api
Vue.prototype.wx = wx

new Vue({
  render: h => h(App),
}).$mount('#app')
